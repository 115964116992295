











import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { MyjbiGroupType } from '@/jbi-shared/types/myjbi-group.types';
import { Group } from '@/store/modules/admin/types/admin.types';
import { get as _get } from 'lodash';

@Component({
  components: {},
  name: 'UserRowPermission'
})
export default class UserRowPermission extends Vue {
  @Prop(Object) public group!: Group;
  @Prop(Function) public getChildren!: (group: Group) => Group[];
  @Prop({ type: Number, default: 1 }) public level!: number;
  @Prop(Boolean) public allGroupsExpanded!: boolean;
  @Prop(Boolean) public expandGroupFilter!: boolean;

  @Prop({ type: Object, default: () => ({}) })
  public filteredUserMembers!: {
    attributes: {
      'first-name'?: string;
      'last-name'?: string;
      email?: string | null;
    };
    emailVerified?: boolean;
    isActiveUser?: boolean;
    userId?: number;
  };

  public showMemberCount: boolean = false;

  expanded = false;

  async navigateToPermissions() {
    const userName =
      this.filteredUserMembers.attributes['first-name'] +
      ' ' +
      this.filteredUserMembers.attributes['last-name'];

    const timestamp = Date.now();

    await this.$router.push({
      name: 'roles-and-permissions',
      query: {
        tab: 'Permissions',
        context: 'users'
      }
    });
    await new Promise((resolve) => setTimeout(resolve, 10));

    await this.$router.push({
      name: 'roles-and-permissions',
      params: { userName },
      query: {
        tab: 'Permissions',
        context: 'users',
        _t: timestamp.toString()
      }
    });
  }

  async clearRouteParamsAndQuery() {
    if (
      this.$route.params.groupName ||
      this.$route.query.tab ||
      this.$route.query.context
    ) {
      await this.$router.replace({
        name: this.$route.name,
        params: {},
        query: {}
      });
    }
  }

  get MyjbiGroupType() {
    return MyjbiGroupType;
  }

  get _get() {
    return _get;
  }

  @Watch('expandGroupFilter', { immediate: true })
  private onOpenGroupsExpandedChanged() {
    this.expanded = this.expandGroupFilter;
  }
}
