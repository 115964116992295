import Vue from 'vue';

Vue.directive('click-outside', {
  inserted: (el: any, binding: any, vNode: any) => {
    el.clickOutsideEvent = (event: any) => {
      if (!(el === event.target || el.contains(event.target))) {
        if (vNode.context) {
          vNode.context[binding.expression](event);
        }
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
    document.body.addEventListener('touchstart', el.clickOutsideEvent);
  },
  unbind: (el: any) => {
    document.body.removeEventListener('click', el.clickOutsideEvent);
    document.body.removeEventListener('touchstart', el.clickOutsideEvent);
  },
  // @ts-ignore
  stopProp(event: any) {
    event.stopPropagation();
  }
});
