var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('table',[_vm._l((_vm.permissionsForModule),function(submodule,index){
var _obj;
return [_c('tbody',[_c('tr',{staticClass:"module",class:( _obj = {}, _obj['rowLevel1'] = _vm.level === 1, _obj['rowLevel2'] = _vm.level === 2, _obj['rowLevel3'] = _vm.level === 3, _obj['rowLevel4'] = _vm.level === 4, _obj['rowLevel5'] = _vm.level === 5, _obj['rowLevel6'] = _vm.level === 6, _obj )},[_c('td',{staticClass:"module-name-container",on:{"click":function($event){return _vm.toggleBranch(index)}}},[_c('div',{staticClass:"module-name is-flex is-flex-center",class:{
              'margin-left-for-modules-without-children': !_vm.isModuleWithChildren(
                submodule
              )
            }},[(submodule.submodules.length > 0)?_c('i',{staticClass:"mdi pr-3",class:{
                'mdi-chevron-down': _vm.getBranchOpenState(index),
                'mdi-chevron-right': !_vm.getBranchOpenState(index)
              }}):_vm._e(),(!_vm.isModuleWithChildren(submodule))?_c('div'):_vm._e(),_c('div',{class:{
                'module-without-children pl-5': !_vm.isModuleWithChildren(
                  submodule
                )
              }},[_c('p',[_vm._v(" "+_vm._s(submodule.label)+" ")]),_c('p',{staticClass:"description"},[_vm._v(_vm._s(submodule.description))])])])]),_vm._l((submodule.permissions),function(permission,userIndex){return [_c('AccessCondition',{key:userIndex,attrs:{"permission-state":permission,"index":_vm.getPermissionComponentIndex(submodule.moduleName, userIndex),"level":_vm.level,"has-submodules":submodule.submodules.length > 0},nativeOn:{"click":function($event){return _vm.setAccessConditions(submodule.moduleName, userIndex, permission)}}})]})],2),(submodule.submodules.length > 0 && _vm.getBranchOpenState(index))?_c('tr',{staticClass:"submodule"},[_c('td',{class:{ 'pt-0 pb-0': _vm.level >= 1 },attrs:{"colspan":"11"}},[_c('ModuleWithPermissions',{attrs:{"overrideOpenState":_vm.overrideOpenState,"permissionsForModule":submodule.submodules}})],1)]):_vm._e()])]})],2)}
var staticRenderFns = []

export { render, staticRenderFns }