var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"permissions-table-container"},[_c('BaseTable',{attrs:{"classes":['no-bottom-border-radius', 'no-top-border-radius'],"table-classes":_vm.tableClasses,"is-borderless":true,"id":"permissions-table"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('td',{staticClass:"module-search"},[_c('div',[_c('b-field',[_c('b-input',{attrs:{"placeholder":"Search permission","type":"search","icon":"magnify"},on:{"input":_vm.searchModuleTreeEvent},model:{value:(_vm.searchTreeQuery),callback:function ($$v) {_vm.searchTreeQuery=$$v},expression:"searchTreeQuery"}})],1)],1)]),(_vm.users && _vm.context === 'users')?_vm._l((_vm.users),function(user){return _c('td',{staticClass:"user-cell"},[_c('div',{staticClass:"user-details"},[_c('p',[_vm._v(_vm._s(user.firstName)+" "+_vm._s(user.lastName))]),_c('p',[_vm._v(_vm._s(user.email))])]),[(user.id >= 0)?_c('TableActionDropdown',{attrs:{"position":'is-bottom-right'}},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.openViewUserGroupsModal(
                    user.id,
                    user.firstName,
                    user.lastName,
                    user.email
                  )}}},[_vm._v(" View Groups ")])],1):_vm._e()]],2)}):_vm._l((_vm.groups),function(group){return _c('td',{staticClass:"user-cell"},[_c('div',{staticClass:"user-details is-clipped"},[_c('p',[_vm._v(_vm._s(group.name))])]),[(group.id >= 0)?_c('TableActionDropdown',{attrs:{"position":'is-bottom-right'}},[_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":function($event){return _vm.openViewUserGroupsModal(group.id, group.name)}}},[_vm._v(" View Members ")])],1):_vm._e()]],2)})]},proxy:true},{key:"body",fn:function(){return [_vm._l((_vm.filteredModuleTree),function(permissionForModule,index){return [_c('tr',{key:index,staticClass:"module"},[_c('td',{staticClass:"module-name level-0-module",on:{"click":function($event){return _vm.toggleBranch(index)}}},[_c('div',{staticClass:"is-flex is-flex-direction-column"},[_c('p',{staticClass:"icon-and-module-name"},[(_vm.isModuleWithChildren(permissionForModule))?_c('i',{staticClass:"mdi pr-3",class:{
                    'mdi-chevron-down': _vm.getBranchOpenState(index),
                    'mdi-chevron-right': !_vm.getBranchOpenState(index)
                  }}):_vm._e(),_c('span',{class:{
                    'padding-left-for-modules-without-children': !_vm.isModuleWithChildren(
                      permissionForModule
                    )
                  }},[_vm._v(_vm._s(permissionForModule.label))])]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(permissionForModule.description)+" ")])])]),_vm._l((permissionForModule.permissions),function(permission,userIndex){return [_c('AccessCondition',{key:userIndex,attrs:{"permission-state":permission,"index":_vm.getPermissionComponentIndex(
                  permissionForModule.moduleName,
                  userIndex
                ),"level":0,"has-submodules":permissionForModule.submodules.length > 0},nativeOn:{"click":function($event){return _vm.setAccessConditions(permissionForModule.moduleName, userIndex)}}})]})],2),(
            _vm.isModuleWithChildren(permissionForModule) &&
            _vm.getBranchOpenState(index)
          )?_c('tr',{staticClass:"submodule"},[_c('td',{attrs:{"colspan":"11"}},[_c('ModuleWithPermissions',{attrs:{"overrideOpenState":_vm.hasQueryString,"permissionsForModule":permissionForModule.submodules}})],1)]):_vm._e()]})]},proxy:true}])}),_c('BaseTable',{attrs:{"id":"permissions-table-footer","is-borderless":true,"classes":['bottom-border-radius-6px']},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('tr',[_c('td'),_c('td',{staticClass:"is-paddingless",attrs:{"colspan":"100%"}},[(_vm.entities && _vm.entities.length > 0)?_c('BasePagination',{attrs:{"page":_vm.page,"total-number-of-page":_vm.items.numberOfPages,"total-count":_vm.items.totalCount,"per-page":_vm.perPage,"is-first-page":_vm.page === 1,"is-last-page":_vm.page === _vm.items.numberOfPages,"start-item-index":_vm.startItemIndex,"end-item-index":_vm.endItemIndex,"per-page-options":[10]},on:{"update:page":[function($event){_vm.page=$event},_vm.updatePage]}}):_c('p',{staticClass:"p-2 has-text-centered"},[_vm._v("No data")])],1)])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }