

























































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Action, State } from 'vuex-class';
import {
  GeneralQueryParams,
  PaginatorSpecs
} from '@/store/types/general.types';
import { SortOrder } from '@/jbi-shared/types/search.types';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import { RootState } from '@/store/store';
import PermissionsForEntityTable from '@/views/RolesAndPermissions/components/PermissionsTable/PermissionsForEntityTable.vue';
import { EntitiesWithPermissionsMatrix } from '@/jbi-shared/types/entities-with-permissions-matrix.interface';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  components: { BasePaginatorHoc }
})
export default class PermissionTab extends Vue {
  @Action('rolesAndPermissions/getPermissionsForUsers')
  getPermissionsForUsers!: (options: GeneralQueryParams) => void;

  @Action('rolesAndPermissions/getPermissionsForGroups')
  getPermissionsForGroups!: (options: GeneralQueryParams) => void;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.permissionsForUsers
  )
  permissionsForUsers!: EntitiesWithPermissionsMatrix;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.permissionsForGroups
  )
  permissionsForGroups!: EntitiesWithPermissionsMatrix;

  // Search form
  private querySubject: Subject<string> = new Subject<string>();
  private searchQuery = '';
  private sortOrder: SortOrder = SortOrder.ASC;
  private isRouteParamNavigation: boolean = false;
  private lastSearchQuery: string = '';
  private sortOptions = [
    { value: SortOrder.ASC, label: 'Sort By: A-Z' },
    { value: SortOrder.DESC, label: 'Sort By: Z-A' }
  ];

  private context: 'users' | 'groups' = 'users';
  private placeholderTextForUsers = 'Search first name, last name or email';
  private placeholderTextForGroups = 'Search for groups';
  private queryParamsForPermissionsForUsersApiCall: GeneralQueryParams = {
    limit: 10,
    page: 1,
    searchQuery: '',
    sortOrder: SortOrder.ASC
  };
  private queryParamsForPermissionsForGroupsApiCall: GeneralQueryParams = {
    limit: 10,
    page: 1,
    searchQuery: '',
    sortOrder: SortOrder.ASC
  };

  private get entities() {
    switch (this.context) {
      case 'users':
        return this.permissionsForUsers;
      case 'groups':
        return this.permissionsForGroups;
      default:
        return this.permissionsForGroups;
    }
  }

  private created() {
    this.getPermissionsForUsers(this.queryParamsForPermissionsForUsersApiCall);
  }

  private mounted() {
    this.onContextParamChange(this.$route.params.context);
    this.updateRoute();
    this.querySubject.pipe(debounceTime(500)).subscribe((query) => {
      this.handleSearch(query);
    });
  }

  private setContextAsUsers() {
    this.context = 'users';
    this.isRouteParamNavigation = false;
    this.refreshList();
  }

  private setContextAsGroups() {
    this.context = 'groups';
    this.isRouteParamNavigation = false;
    this.refreshList();
  }

  private modifyQueryParamsForPermissionsForUsersApiCall<T>(
    paramsMap: Map<string, T>
  ) {
    for (const [key, value] of paramsMap.entries()) {
      if (key in this.queryParamsForPermissionsForUsersApiCall) {
        // Use type assertion to safely assign the value
        (this.queryParamsForPermissionsForUsersApiCall as Record<string, any>)[
          key
        ] = value;
        // TODO: Change page query params as well
      }
    }
  }

  private modifyQueryParamsForPermissionsForGroupsApiCall<T>(
    paramsMap: Map<string, T>
  ) {
    for (const [key, value] of paramsMap.entries()) {
      if (key in this.queryParamsForPermissionsForUsersApiCall) {
        // Use type assertion to safely assign the value
        (this.queryParamsForPermissionsForGroupsApiCall as Record<string, any>)[
          key
        ] = value;
      }
    }
  }

  private handleSearch(searchQuery: string): void {
    const map = new Map();
    map.set('page', 1);
    map.set('searchQuery', searchQuery);

    switch (this.context) {
      case 'users':
        this.modifyQueryParamsForPermissionsForUsersApiCall(map);
        break;
      case 'groups':
        this.modifyQueryParamsForPermissionsForGroupsApiCall(map);
        break;
      default:
        this.modifyQueryParamsForPermissionsForUsersApiCall(map);
        break;
    }
    this.refreshList();
  }

  private handlePaginator(paginator: PaginatorSpecs): void {
    const map = new Map();
    map.set('limit', paginator.perPage);
    map.set('page', paginator.page);
    switch (this.context) {
      case 'users':
        this.modifyQueryParamsForPermissionsForUsersApiCall(map);
        break;
      case 'groups':
        this.modifyQueryParamsForPermissionsForGroupsApiCall(map);
        break;
    }
    this.refreshList();
  }

  private handleSort(sort: SortOrder): void {
    const map = new Map();
    map.set('sortOrder', sort);

    switch (this.context) {
      case 'users':
        this.modifyQueryParamsForPermissionsForUsersApiCall(map);
        break;
      case 'groups':
        this.modifyQueryParamsForPermissionsForGroupsApiCall(map);
        break;
    }

    this.refreshList();
  }

  private searchEntitiesList(query: string): void {
    this.querySubject.next(query);
  }

  private get queryParams() {
    switch (this.context) {
      case 'users':
        return this.queryParamsForPermissionsForUsersApiCall;
      case 'groups':
        return this.queryParamsForPermissionsForGroupsApiCall;
      default:
        return this.queryParamsForPermissionsForUsersApiCall;
    }
  }

  private updateRoute(): void {
    // Ensure the context is explicitly set in the query params
    this.$router.push({
      path: this.$route.path,
      query: {
        tab: 'Permissions',
        context: this.context, // Always set this from current state
        searchQuery: this.queryParams.searchQuery,
        limit:
          (this.$route.query.limit as string) ||
          this.queryParams.limit.toString(),
        page:
          (this.$route.query.page as string) ||
          this.queryParams.page.toString(),
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      }
    });
  }

  private get PermissionsForEntityTable() {
    return PermissionsForEntityTable;
  }

  private refreshList() {
    // Modified to always fetch if context changes
    const contextChanged = this.$route.query.context !== this.context;

    if (
      contextChanged ||
      !this.isRouteParamNavigation ||
      this.searchQuery !== this.lastSearchQuery
    ) {
      switch (this.context) {
        case 'users':
          this.getPermissionsForUsers(
            this.queryParamsForPermissionsForUsersApiCall
          );
          break;
        case 'groups':
          this.getPermissionsForGroups(
            this.queryParamsForPermissionsForGroupsApiCall
          );
          break;
        default:
          this.getPermissionsForUsers(
            this.queryParamsForPermissionsForUsersApiCall
          );
          break;
      }
    }
    this.isRouteParamNavigation = false;
    this.updateRoute();
  }

  @Watch('$route.query.context', { immediate: true })
  private onContextParamChange(newContext: string) {
    this.context = newContext === 'groups' ? 'groups' : 'users';
  }

  @Watch('$route.params', { immediate: true, deep: true })
  private onRouteParamsChange(newParams: {
    groupName?: string;
    userName?: string;
  }) {
    if (newParams.groupName) {
      // Always set these flags, not just on first load
      this.isRouteParamNavigation = true;
      this.lastSearchQuery = newParams.groupName;
      this.searchQuery = newParams.groupName;

      // Important: Set context to 'groups' BEFORE modifying query params
      this.context = 'groups';

      const map = new Map();
      map.set('page', 1);
      map.set('searchQuery', newParams.groupName);

      // Now we're sure we're in groups context
      this.modifyQueryParamsForPermissionsForGroupsApiCall(map);
      this.getPermissionsForGroups(
        this.queryParamsForPermissionsForGroupsApiCall
      );

      // Force update route with new context
      this.$nextTick(() => {
        this.updateRoute();
      });
    } else if (newParams.userName) {
      // Similar logic for user name
      this.isRouteParamNavigation = true;
      this.lastSearchQuery = newParams.userName;
      this.searchQuery = newParams.userName;

      this.context = 'users';

      const map = new Map();
      map.set('page', 1);
      map.set('searchQuery', newParams.userName);

      this.modifyQueryParamsForPermissionsForUsersApiCall(map);
      this.getPermissionsForUsers(
        this.queryParamsForPermissionsForUsersApiCall
      );

      // Force update route with new context
      this.$nextTick(() => {
        this.updateRoute();
      });
    }
  }

  /**
   * This watcher is to account for component re-render after reloading on other tabs.
   * This is because tab switching does not trigger component re-render, hence any logic
   * within the `mounted()` or `created()` hooks will be ignored.
   *
   * i.e User reloads in 'Exceptions' tab then redirects to this tab, the watcher notices
   * tab switch and triggers re-render, without which the current tab will be empty.
   */
  @Watch('$route.query.tab')
  private routeParam() {
    if (this.$route.query.tab === 'Permissions') {
      this.updateRoute();
    }
  }

  @Watch('context')
  private watchContext() {
    if (this.context) {
      // Important: Add a flag check to prevent double refreshing
      if (!this.isRouteParamNavigation) {
        this.refreshList();
      }
    }
  }
}
