






















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { MyjbiGroupType } from '@/jbi-shared/types/myjbi-group.types';
import { Group } from '@/store/modules/admin/types/admin.types';
import { get as _get } from 'lodash';

@Component({
  components: {},
  name: 'GroupRowPermission'
})
export default class GroupRowPermission extends Vue {
  @Prop(Object) public group!: Group;
  @Prop(Function) public getChildren!: (group: Group) => Group[];
  @Prop({ type: Number, default: 1 }) public level!: number;
  @Prop(Boolean) public allGroupsExpanded!: boolean;
  @Prop(Boolean) public expandGroupFilter!: boolean;
  public showMemberCount: boolean = false;

  expanded = false;

  async navigateToPermissions(groupName: string) {
    await this.$router.push({ name: 'roles-and-permissions' });
    this.$router.push({
      name: 'roles-and-permissions',
      params: { groupName },
      query: { tab: 'Permissions', context: 'groups' }
    });
  }

  async clearRouteParamsAndQuery() {
    if (
      this.$route.params.groupName ||
      this.$route.query.tab ||
      this.$route.query.context
    ) {
      await this.$router.replace({
        name: this.$route.name,
        params: {},
        query: {}
      });
    }
  }

  get groupname() {
    return this.group.name;
  }

  get descendants() {
    return this.getChildren(this.group);
  }

  get noSubGroup() {
    return !this.descendants.length;
  }

  get MyjbiGroupType() {
    return MyjbiGroupType;
  }

  get _get() {
    return _get;
  }

  @Watch('expandGroupFilter', { immediate: true })
  private onOpenGroupsExpandedChanged() {
    this.expanded = this.expandGroupFilter;
  }
}
