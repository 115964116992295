
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { PermissionsForModule } from '@/jbi-shared/types/entities-versus-modules.interface';
import AccessCondition from '@/views/RolesAndPermissions/components/PermissionsTable/AccessCondition.vue';
import { Action } from 'vuex-class';

@Component({
  name: 'ModuleWithPermissions',
  components: { AccessCondition }
})
export default class ModuleWithPermissions extends Vue {
  @Prop()
  permissionsForModule!: PermissionsForModule[];

  @Prop({ default: false }) overrideOpenState!: boolean;

  @Action('rolesAndPermissions/setCurrentEntityInView')
  private setCurrentEntityInView!: (payload: string) => void;

  @Action('rolesAndPermissions/unsetCurrentEntityInView')
  private unsetCurrentEntityInView!: () => void;

  // Variable that stores open state for module branches
  private branchVersusOpenState: boolean[] = [];

  private level: number = 0;

  private created() {
    if (this.permissionsForModule) {
      this.loadPermissionOpenStates();
    }
  }

  private loadPermissionOpenStates(): void {
    this.permissionsForModule.forEach(() =>
      this.branchVersusOpenState.push(this.overrideOpenState)
    );

    this.level = this.permissionsForModule[0].level;
  }

  private toggleBranch(index: number) {
    this.$set(
      this.branchVersusOpenState,
      index,
      !this.branchVersusOpenState[index]
    );
    this.unsetCurrentEntityInView();
  }

  private isModuleWithChildren(data: PermissionsForModule) {
    return data.submodules.length > 0;
  }

  private getBranchOpenState(index: number) {
    return this.branchVersusOpenState[index];
  }

  private getPermissionComponentIndex(moduleName: string, userIndex: number) {
    return `${moduleName}__${userIndex}`;
  }

  private setAccessConditions(
    moduleName: string,
    userIndex: number,
    permission: string
  ) {
    if (permission === 'none') {
      return;
    }
    this.setCurrentEntityInView(`${moduleName}__${userIndex}`);
  }

  @Watch('overrideOpenState')
  private onOverrideOpenStateGiven() {
    this.branchVersusOpenState = [];
    for (const item of this.permissionsForModule) {
      // override open state
      this.branchVersusOpenState.push(this.overrideOpenState);
    }
  }

  @Watch('permissionsForModule', { deep: true })
  private onPermissionsForModuleChange() {
    this.loadPermissionOpenStates();
  }
}
