import { render, staticRenderFns } from "./PermissionsForEntityTable.vue?vue&type=template&id=1b528d14&scoped=true&"
import script from "./PermissionsForEntityTable.vue?vue&type=script&lang=ts&"
export * from "./PermissionsForEntityTable.vue?vue&type=script&lang=ts&"
import style0 from "./PermissionsForEntityTable.vue?vue&type=style&index=0&id=1b528d14&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b528d14",
  null
  
)

export default component.exports