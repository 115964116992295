interface TabData<T> {
    [key: string]: T;
}

export const rolesAndPermissionsTabs: Array<TabData<string>> = [
    {
        tab: 'Roles',
        role: '',
        limit: '50',
        page: '1',
        sortColumn: 'role',
        sortOrder: 'ASC',
    },
    {
        tab: 'Permissions',
        context: 'users',
        searchQuery: '',
        limit: '10',
        page: '1',
        sortOrder: 'ASC',
    },
    // Left empty for future extension purposes
    {
        tab: 'Exceptions',
    },
    {
        tab: 'Users',
    },
    {
        tab: 'Groups',
    },
    {
        tab: 'RoleTemplates',
        roleTemplate: '',
        limit: '50',
        page: '1',
        sortColumn: 'roleTemplate',
        sortOrder: 'ASC',
    },
];
