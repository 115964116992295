
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import '../../../../utils/click-outside.directive';
import { Action, State } from 'vuex-class';
import { RootState } from '@/store/store';
import { AccessCondition as IAccessCondition } from '@/jbi-shared/types/access-condition.interface';

@Component({})
export default class AccessCondition extends Vue {
  @Prop()
  permissionState!: string;

  @Prop()
  index!: string;

  @Prop()
  level!: number;

  @Prop()
  hasSubmodules!: boolean;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.currentEntityInView
  )
  private currentEntityInView!: string;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.accessConditionsForEntity
  )
  private accessConditionsForEntity!: IAccessCondition;

  @Action('rolesAndPermissions/unsetCurrentEntityInView')
  private unsetCurrentEntityInView!: () => void;

  private isAccessConditionModalVisible: boolean = false;

  mounted(): void {
    document.addEventListener('click', this.handleClickOutside);
  }

  private get stateIcon(): string {
    switch (this.permissionState) {
      case 'allow':
        return `mdi mdi-check checked ${
          this.isAccessConditionModalVisible
            ? 'access-conditions-modal-active'
            : ''
        }`;
      case 'deny':
        return `mdi mdi-close unchecked ${
          this.isAccessConditionModalVisible
            ? 'access-conditions-modal-active'
            : ''
        }`;
      case 'mixed':
        return `mdi mdi-minus mixed ${
          this.isAccessConditionModalVisible
            ? 'access-conditions-modal-active'
            : ''
        }`;
      default:
        return ``;
    }
  }

  private get permissionStateText(): string {
    switch (this.permissionState) {
      case 'allow':
        return '<span class="granted">granted</span>';
      case 'deny':
        return '<span class="denied">denied</span>';
      case 'mixed':
        return '<span class="mixed-permission">mixed</span>';
      default:
        return '<span class="no-permission">no permission</span>';
    }
  }

  private getPermissionsSource(source: string) {
    switch (source) {
      case 'individual':
        return 'Individually';
      case 'group':
        return 'Created Through a Group';
      default:
        return 'Individual';
    }
  }

  /**
   * Access conditions are shown according to priority: exceptions are shown
   * roles. Within exceptions, exceptions applied individually
   * to a user are shown before exceptions by proxy via groups. Additionally,
   * exceptions applied to specific instances are shown before ones applied
   * to the module itself.
   * @private
   */
  private get accessConditionsMarkup() {
    // Collating state of access conditions
    const numberOfExceptionsThroughModules = this.accessConditionsForEntity
      .exceptions?.modules?.length;
    const numberOfExceptionsThroughInstances = this.accessConditionsForEntity
      .exceptions?.instances?.length;
    const instanceExceptionsExist =
      numberOfExceptionsThroughInstances &&
      numberOfExceptionsThroughInstances > 0;
    const moduleExceptionsExist =
      numberOfExceptionsThroughModules && numberOfExceptionsThroughModules > 0;
    const instanceExceptions = this.accessConditionsForEntity.exceptions
      ?.instances;
    const moduleExceptions = this.accessConditionsForEntity.exceptions?.modules;
    const numberOfRoles = this.accessConditionsForEntity.roles?.length;

    const errorMarkup = `<p>Error retrieving access conditions</p>`;
    const noPermissionsSetMarkup = `<p>No permissions set for this module</p>`;
    let accessConditionsMarkup = '';

    if (!this.accessConditionsForEntity) {
      return errorMarkup;
    }

    if (
      this.accessConditionsForEntity.roles?.length === 0 &&
      this.accessConditionsForEntity.exceptions?.modules?.length === 0 &&
      this.accessConditionsForEntity.exceptions?.instances?.length === 0
    ) {
      return noPermissionsSetMarkup;
    }

    /**
     * Modules that have children get a summary of their access conditions
     */
    if (this.hasSubmodules) {
      if (
        (instanceExceptionsExist || moduleExceptionsExist) &&
        numberOfRoles &&
        numberOfRoles > 0
      ) {
        accessConditionsMarkup += `<p class="section-title-l1 mb-4">Role and Exception</p>`;
        accessConditionsMarkup += `<p>Sub-permissions ${this.permissionStateText} through role and exception</p>`;
      } else if (
        (instanceExceptionsExist || moduleExceptionsExist) &&
        numberOfRoles === 0
      ) {
        accessConditionsMarkup += `<p>Sub-permissions ${this.permissionStateText} through exception</p>`;
      } else {
        accessConditionsMarkup += `<p>Sub-permissions ${this.permissionStateText} through role</p>`;
      }
    } else {
      /**
       * Show exceptions before permissions through roles
       */
      if (
        numberOfExceptionsThroughInstances ||
        numberOfExceptionsThroughModules
      ) {
        accessConditionsMarkup += `<p class="section-title-l1 mb-4">Exception</p>`;
        if (instanceExceptions && instanceExceptions?.length > 0) {
          /**
           * isie = individual source instance exceptions
           * gsie = group source instance exceptions
           */
          const isie =
            instanceExceptions &&
            instanceExceptions.filter((item) => item.source === 'individual');
          const gsie =
            instanceExceptions &&
            instanceExceptions.filter((item) => item.source === 'group');

          const isieallow =
            isie && isie.filter((item: any) => item.access === 'allow');
          const isiedeny =
            isie && isie.filter((item: any) => item.access === 'deny');

          const gsieallow =
            gsie && gsie.filter((item: any) => item.access === 'allow');
          const gsiedeny =
            gsie && gsie.filter((item: any) => item.access === 'deny');

          if (isiedeny && isiedeny.length > 0) {
            accessConditionsMarkup += `<p class="section-title-l2 mb-2">Created ${this.getPermissionsSource(
              'individual'
            )}</p>`;
            accessConditionsMarkup += `<p>Status: <span class="denied">Permission blocked</span> for selected instance(s)</p>`;
            accessConditionsMarkup += `<p>Instance:</p><ul class="mb-2">`;
            for (const item of isiedeny) {
              accessConditionsMarkup += `<li>${item.instanceName}</li>`;
            }
            accessConditionsMarkup += `</ul>`;
          }

          if (isieallow && isieallow.length > 0) {
            accessConditionsMarkup += `<p class="section-title-l2 mb-2">Created ${this.getPermissionsSource(
              'individual'
            )}</p>`;
            accessConditionsMarkup += `<p>Status: <span class="granted">Permission granted</span> for selected instance(s)</p>`;
            accessConditionsMarkup += `<p>Instance:</p><ul class="mb-2">`;
            for (const item of isieallow) {
              accessConditionsMarkup += `<li>${item.instanceName}</li>`;
            }
            accessConditionsMarkup += `</ul>`;
          }

          if (gsie && gsie.length > 0) {
            accessConditionsMarkup += `<p class="section-title-l2 mb-2">Created ${this.getPermissionsSource(
              'group'
            )}</p>`;
            if (gsieallow && gsieallow.length > 0) {
              accessConditionsMarkup += `<p>Status: <span class="denied">Permission blocked</span> for selected instance(s)</p>`;
              accessConditionsMarkup += `<p>Instance:</p><ul>`;
              gsieallow.forEach((item: any) => {
                accessConditionsMarkup += `<li>${item.instanceName}</li>`;
              });
              accessConditionsMarkup += `</ul>`;
            }

            if (gsiedeny && gsiedeny.length > 0) {
              accessConditionsMarkup += `<p>Status: <span class="granted">Permission granted</span> for selected instance(s)</p>`;
              accessConditionsMarkup += `<p>Instance:</p><ul>`;
              for (const item of isiedeny) {
                accessConditionsMarkup += `<li>${item.instanceName}</li>`;
              }
              accessConditionsMarkup += `</ul>`;
            }
          }
        }

        if (moduleExceptions && moduleExceptions?.length > 0) {
          for (const moduleException of moduleExceptions) {
            accessConditionsMarkup += `<p class="section-title-l2 mb-2">Created ${this.getPermissionsSource(
              moduleException.source
            )}</p>`;
            const permissionClass =
              moduleException.access === 'allow' ? 'granted' : 'denied';
            accessConditionsMarkup += `<p>Status: <span class="${permissionClass}">Permission ${
              moduleException.access === 'allow' ? 'granted' : 'blocked'
            }</span> for a module</p>`;
          }
        }
      }

      // Divider
      if (
        (numberOfExceptionsThroughModules ||
          numberOfExceptionsThroughInstances) &&
        numberOfRoles
      ) {
        accessConditionsMarkup += `<div class="divider"></div>`;
      }

      // Permissions through roles
      if (numberOfRoles && numberOfRoles > 0) {
        accessConditionsMarkup += `<p class="section-title-l1 mb-4">Role</p>`;

        for (const role of this.accessConditionsForEntity.roles || []) {
          accessConditionsMarkup += `<p><b>${role.roleName}</b><p>`;
          // If role targets instances, render list of instances it targets
          if (
            role?.permissions?.instances?.length &&
            role?.permissions?.instances?.length > 0
          ) {
            const instances = role.permissions.instances;
            if (instances) {
              accessConditionsMarkup += `<p class="role-source mb-2">Created ${this.getPermissionsSource(
                instances[0].source
              )}</p>`;
              accessConditionsMarkup += `<p class="mb-2">Status: <span class="granted">Permission granted</span> for selected group(s)</p><p>Scope:</p><ul>`;
              for (const instance of role.permissions.instances) {
                accessConditionsMarkup += `<li>${instance.instanceName}</li>`;
              }
            }
          }

          if (
            role?.permissions?.subdivisions?.length &&
            role?.permissions?.subdivisions?.length > 0
          ) {
            const subdivisions = role.permissions.subdivisions;
            if (subdivisions) {
              accessConditionsMarkup += `<p class="role-source mb-2">Created ${this.getPermissionsSource(
                subdivisions[0].source
              )}</p>`;
              accessConditionsMarkup += `<p class="mb-2">Status: <span class="granted">Permission granted</span> for selected group type(s)</p><p>Scope:</p><ul>`;
              for (const subdivision of subdivisions) {
                accessConditionsMarkup += `<li>${subdivision.subdivisionName}</li>`;
              }
            }
          }

          if (role.permissions.module) {
            const module = role.permissions.module;
            accessConditionsMarkup += `<p class="role-source mb-2">Created ${this.getPermissionsSource(
              module.source
            )}</p>`;
            accessConditionsMarkup += `<p class="mb-2">Status: <span class="granted">Permission granted</span> for all groups</p>`;
          }
        }
      }
    }

    return accessConditionsMarkup;
  }

  /**
   * If the user clicks anywhere outside the component, hide the access
   * condition component.
   * @param event
   */
  handleClickOutside(event: MouseEvent): void {
    const target = event.target as Node;

    if (this.$el && !this.$el.contains(target)) {
      this.isAccessConditionModalVisible = false;
    }
  }

  private toggleAccessConditionModal(): void {
    if (!this.currentEntityInView || this.index !== this.currentEntityInView) {
      this.isAccessConditionModalVisible = false;
    } else {
      this.isAccessConditionModalVisible = !this.isAccessConditionModalVisible;
    }

    if (this.permissionState === 'none') {
      this.isAccessConditionModalVisible = false;
    }
  }

  @Watch('currentEntityInView')
  private unsetAccessConditionModal() {
    if (!this.currentEntityInView || this.currentEntityInView !== this.index) {
      this.isAccessConditionModalVisible = false;
    }
  }

  beforeDestroy(): void {
    document.removeEventListener('click', this.handleClickOutside);
  }
}
