
import { Prop, Watch } from 'vue-property-decorator';
import { PermissionsForModule } from '@/jbi-shared/types/entities-versus-modules.interface';
import { SortOrder } from '@/jbi-shared/types/search.types';
import BaseTable from '@/components/BaseTable.vue';
import BasePagination from '@/components/base/BasePagination.vue';
import BaseLoading from '@/components/base/BaseLoading.vue';
import ModuleWithPermissions from '@/views/RolesAndPermissions/components/PermissionsTable/ModuleWithPermissions.vue';
import AccessCondition from '@/views/RolesAndPermissions/components/PermissionsTable/AccessCondition.vue';
import { Action } from 'vuex-class';
import { RootState } from '@/store/store';
import TableActionDropdown from '@/components/TableActionDropdown.vue';
import { Vue, Component } from 'vue-property-decorator';
import { State } from 'vuex-class';
import ViewUserGroupsWithPermissionModal from '@/views/RolesAndPermissions/components/ViewUserGroupsWithPermissionModal.vue';
import { Subject } from 'rxjs';
import { EntitiesWithPermissionsMatrix } from '@/jbi-shared/types/entities-with-permissions-matrix.interface';
import { UserPermissions } from '@/jbi-shared/types/user-permissions.interface';

@Component({
  components: {
    AccessCondition,
    ModuleWithPermissions,
    TableActionDropdown,
    BaseTable,
    BasePagination,
    BaseLoading
  }
})
export default class PermissionsForEntityTable extends Vue {
  @Prop()
  public items!: EntitiesWithPermissionsMatrix;

  @Prop({ default: SortOrder.ASC })
  public sortOrder!: SortOrder;

  @Prop({ default: '' })
  public searchQuery!: string;

  @Prop()
  public context!: string;

  @Action('rolesAndPermissions/setCurrentEntityInView')
  private setCurrentEntityInView!: (payload: string) => void;

  @Action('rolesAndPermissions/unsetCurrentEntityInView')
  private unsetCurrentEntityInView!: () => void;

  @Action('rolesAndPermissions/getAccessConditionsForEntity')
  private getAccessConditionsForEntity!: (payload: {
    moduleName: string;
    entityId: string;
  }) => void;

  @State(
    ({ rolesAndPermissions }: RootState) =>
      rolesAndPermissions.currentEntityInView
  )
  private currentEntityInView!: string;

  private page = 1;
  private perPage = 10;
  private searchTreeQuery = '';
  private querySubject: Subject<string> = new Subject<string>();
  private filteredModules: PermissionsForModule[] = [];
  private moduleTreeNewRef: PermissionsForModule[] = [];

  private tableClasses = [
    'table-layout-fixed',
    'separate-border-collapse',
    'mb-0'
  ];

  // Variable that stores open state for module branches
  private branchVersusOpenState: boolean[] = [];

  private created() {
    if (this.items.permissionsForModules) {
      for (const item of this.items.permissionsForModules) {
        // All items should be collapsed by default
        this.branchVersusOpenState.push(false);
      }
    }
  }

  private mounted(): void {
    this.querySubject.subscribe((query) => {
      this.filterModuleTree(query);
    });

    /**
     * Triggering querySubject to run filterModuleTree() to ensure
     * moduleTreeNewRef is populated.
     */
    this.querySubject.next();
  }

  private get permissionsForModules(): EntitiesWithPermissionsMatrix['permissionsForModules'] {
    return this.items.permissionsForModules;
  }

  private get filteredModuleTree() {
    return this.moduleTreeNewRef;
  }

  private filterModuleTree(query: string) {
    this.filteredModules = [];
    this.moduleTreeNewRef = JSON.parse(
      JSON.stringify(this.items.permissionsForModules)
    );

    if (query) {
      this.moduleTreeNewRef = this.searchModuleTree(
        this.moduleTreeNewRef,
        query.toLowerCase().trim()
      );
    }

    return this.moduleTreeNewRef;
  }

  // TODO: Ideally, move this into the backend, will serve as good-enough fix for now
  // since data set is still small and not too deeply nested.
  /**
   * Recursively searches a module tree for items matching the query string.
   * This handles nested hierarchies at any depth level.
   *
   * The algorithm:
   * 1. Traverses each module in the tree
   * 2. Determines if the current module or any of its children match
   * 3. Adds only matching modules while preserving parent-child relationships
   * 4. Prevents duplicate modules in the results
   *
   * @param moduleTree - Array of modules to search through
   * @param query - Search term to match against module labels
   * @param parentModule - Optional parent module reference for maintaining hierarchy
   * @returns Filtered array of modules with matching items
   */
  private searchModuleTree(
    moduleTree: PermissionsForModule[],
    query: string,
    parentModule?: PermissionsForModule
  ): PermissionsForModule[] {
    for (const module of moduleTree) {
      const rootModule = module.level === 0 ? module : parentModule;

      if (!rootModule) {
        continue;
      }

      // Create a deep copy of the current module
      const matchingModule = this.createFilteredModuleCopy(module);
      let hasMatchingChildren = false;

      // Recursively filter and check all levels of submodules
      if (module.submodules.length > 0) {
        matchingModule.submodules = this.filterSubmodulesRecursively(
          module.submodules,
          query
        );
        // Track if any matching children were found
        hasMatchingChildren = matchingModule.submodules.length > 0;
      }

      // Check if current module matches the query
      const currentModuleMatches = module.label
        ?.toLowerCase()
        .trim()
        .includes(query.toLowerCase().trim());

      // Proceed if either this module or any of its children match the query
      if (currentModuleMatches || hasMatchingChildren) {
        // Find existing root module in filtered results
        const existingRootModuleIndex = this.filteredModules.findIndex(
          (item) => item.moduleName === rootModule.moduleName
        );

        // Scenario 1: This is a new root module that HAS NOT BEEN traversed
        if (existingRootModuleIndex === -1) {
          // Add new root module with only matching submodules
          const filteredRootModule = this.createFilteredModuleCopy(rootModule);

          /**
           * Get all matching submodules, filtering out any duplicates.
           * This is important when multiple paths through the tree lead to the same node,
           * else, duplicate root/submodules get appended
           */
          const uniqueSubmodules = this.filterSubmodulesRecursively(
            rootModule.submodules,
            query
          ).filter(
            (submodule) =>
              !this.isDuplicateModule(submodule, filteredRootModule.submodules)
          );

          filteredRootModule.submodules = uniqueSubmodules;
          this.filteredModules.push(filteredRootModule);
        } else {
          // Scenario 2: Traversed root module found, merge with unique matching submodules
          const existingModule = this.filteredModules[existingRootModuleIndex];
          this.mergeUniqueSubmodules(existingModule, matchingModule);
        }
      }

      // Continue searching in submodules if they exist
      if (module.submodules.length > 0) {
        this.searchModuleTree(module.submodules, query, rootModule);
      }
    }

    return this.filteredModules;
  }

  /**
   * Helper method that checks if a module already exists in the provided array.
   * This prevents duplicate modules in the search results.
   *
   * @param moduleToCheck - Module to look for
   * @param existingModules - Array of modules to search within
   * @returns True if module exists, false otherwise
   */
  private isDuplicateModule(
    moduleToCheck: PermissionsForModule,
    existingModules: PermissionsForModule[]
  ): boolean {
    return existingModules.some((existing) => {
      const isDuplicate = existing.moduleName === moduleToCheck.moduleName;

      if (isDuplicate) {
        return true;
      }

      if (existing.submodules.length > 0) {
        return this.isDuplicateModule(moduleToCheck, existing.submodules);
      }

      return false;
    });
  }

  /**
   * Helper method that merges unique submodules from source into target module.
   * This maintains the hierarchy while avoiding duplicates.
   *
   * Algorithm:
   * 1. For each submodule in source, check if it already exists in target
   * 2. If not a duplicate, add it to target
   * 3. If it has its own submodules, recursively merge those too
   *
   * @param target - Destination module to merge into
   * @param source - Source module to merge from
   */
  private mergeUniqueSubmodules(
    target: PermissionsForModule,
    source: PermissionsForModule
  ): void {
    source.submodules.forEach((incomingModule) => {
      // Check if this module or any of its parents already exist
      if (!this.isDuplicateModule(incomingModule, target.submodules)) {
        const filteredModule = this.createFilteredModuleCopy(incomingModule);

        // Recursively merge any nested submodules
        if (incomingModule.submodules.length > 0) {
          const existingModule = this.findModuleByName(
            target.submodules,
            incomingModule.moduleName
          );

          if (existingModule) {
            this.mergeUniqueSubmodules(existingModule, incomingModule);
          } else {
            filteredModule.submodules = [...incomingModule.submodules];
          }
        }

        target.submodules.push(filteredModule);
      }
    });
  }

  /**
   * Finds a module by name in the given array and its nested submodules.
   * This is a depth-first search through the module hierarchy.
   *
   * @param modules - Array of modules to search through
   * @param moduleName - Name of the module to find
   * @returns The found module if it exists, or undefined if no matching module is found
   */
  private findModuleByName(
    modules: PermissionsForModule[],
    moduleName: string
  ): PermissionsForModule | undefined {
    for (const module of modules) {
      if (module.moduleName === moduleName) {
        return module;
      }

      if (module.submodules.length > 0) {
        const foundModule = this.findModuleByName(
          module.submodules,
          moduleName
        );
        // Return immediately if found
        if (foundModule) {
          return foundModule;
        }
      }
    }

    return undefined;
  }

  /**
   * Helper method that recursively filters a set of submodules based on the query,
   * returning only the matching branches. Focuses solely on determining which modules
   * match the query or have matching descendants.
   *
   * The algorithm:
   * 1. Preserves the hierarchy structure
   * 2. Only includes modules that match or have matching descendants
   * 3. Removes branches with no matches
   *
   * @param submodules - Array of submodules to filter
   * @param query - Search term to match against module labels
   * @returns Filtered array containing only matching modules and their ancestors
   */
  private filterSubmodulesRecursively(
    submodules: PermissionsForModule[],
    query: string
  ): PermissionsForModule[] {
    return (
      submodules
        .map((submodule) => {
          const matches = submodule.label
            ?.toLowerCase()
            .trim()
            .includes(query.toLowerCase().trim());
          const filteredSubmodule = this.createFilteredModuleCopy(submodule);

          if (submodule.submodules.length > 0) {
            filteredSubmodule.submodules = this.filterSubmodulesRecursively(
              submodule.submodules,
              query
            );
          }

          /**
           * Include this module if:
           * 1. It matches the query directly, OR
           * 2. Any of its children match (has filtered submodules)
           */
          if (matches || filteredSubmodule.submodules.length > 0) {
            return filteredSubmodule;
          }

          // Exclude otherwise
          return null;
        })
        // Type guard to filter out null values and maintain proper typescript typing
        .filter(
          (submodule): submodule is PermissionsForModule => submodule !== null
        )
    );
  }

  /**
   * Creates a shallow copy of a module with empty submodules array.
   * This prevents modifying the original module tree during filtering.
   *
   * @param module - Source module to copy
   * @returns New module instance with copied properties and empty submodules array
   */
  private createFilteredModuleCopy(
    module: PermissionsForModule
  ): PermissionsForModule {
    return {
      moduleName: module.moduleName,
      label: module.label,
      description: module.description,
      level: module.level,
      permissions: [...module.permissions],
      submodules: []
    };
  }

  private searchModuleTreeEvent(query: string): void {
    this.searchTreeQuery = query;
    this.querySubject.next(query);
  }

  private get users() {
    return this.items.entities as UserPermissions[];
  }

  private get groups() {
    return this.items.entities as Array<{ id: number; name: string }>;
  }

  private get entities() {
    return this.items.entities;
  }

  get startItemIndex(): number {
    return this.page * this.perPage - this.perPage + 1;
  }

  get endItemIndex(): number {
    return Math.min(this.items.totalCount, this.page * this.perPage);
  }

  get hasQueryString(): boolean {
    return this.searchTreeQuery !== '';
  }

  private toggleBranch(index: number) {
    this.$set(
      this.branchVersusOpenState,
      index,
      !this.branchVersusOpenState[index]
    );
    this.unsetCurrentEntityInView();
  }

  private setAccessConditions(moduleName: string, userIndex: number) {
    this.setCurrentEntityInView(`${moduleName}__${userIndex}`);
  }

  private isModuleWithChildren(data: PermissionsForModule) {
    return data.submodules.length > 0;
  }

  private getBranchOpenState(index: number) {
    return this.branchVersusOpenState[index];
  }

  private getPermissionComponentIndex(moduleName: string, userIndex: number) {
    return `${moduleName}__${userIndex}`;
  }

  private updatePage(page: number) {
    this.page = +page;
  }

  private openViewUserGroupsModal(
    id: number,
    firstName?: string,
    lastName?: string,
    email?: string,
    groupname?: string
  ): void {
    this.$buefy.modal.open({
      parent: this,
      component: ViewUserGroupsWithPermissionModal,
      hasModalCard: true,
      props: {
        id,
        firstName,
        lastName,
        email,
        groupname
      },
      events: {}
    });
  }

  @Watch('currentEntityInView')
  private watchCurrentEntityInView() {
    if (this.currentEntityInView) {
      const split = this.currentEntityInView.split('__');
      if (this.users && this.users[Number(split[1])]) {
        const targetUserId = this.users[Number(split[1])];
        this.getAccessConditionsForEntity({
          moduleName: split[0],
          entityId: targetUserId.id.toString()
        });
      }
    }
  }

  @Watch('page')
  private watchPage() {
    this.$emit('paginate', { page: this.page, perPage: this.perPage });
  }

  @Watch('items')
  private watchItems() {
    if (this.items) {
      this.moduleTreeNewRef = this.items.permissionsForModules;
      this.querySubject.next(this.searchTreeQuery);
    }
  }

  @Watch('hasQueryString')
  private onQueryStringGiven() {
    this.branchVersusOpenState = [];
    for (const item of this.filteredModuleTree) {
      // override open state
      this.branchVersusOpenState.push(this.hasQueryString);
    }
  }
}
