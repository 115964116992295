











































import { Component, Vue, Watch } from 'vue-property-decorator';
import Container from '../../components/Container.vue';
import PermissionHelperModal from './components/PermissionHelperModal.vue';
import ExceptionTab from './Exception.tab.vue';
import GroupTab from './Group.tab.vue';
import PermissionTab from './Permission.tab.vue';
import RoleTab from './Role.tab.vue';
import RoleTemplateTab from './RoleTemplate.tab.vue';
import UserTab from './User.tab.vue';
import { rolesAndPermissionsTabs } from '@/jbi-shared/types/roles-and-permissions.tabs';

@Component({
  components: {
    Container,
    RoleTab,
    PermissionTab,
    ExceptionTab,
    UserTab,
    GroupTab,
    RoleTemplateTab
  }
})
export default class RolesAndPermissions extends Vue {
  public activeTab = 0;

  public getTabIndex(value: string): number {
    return rolesAndPermissionsTabs.findIndex((tab) => tab.tab === value);
  }

  public mounted(): void {
    if (this.$route.query.tab) {
      const index = rolesAndPermissionsTabs.findIndex(
        (res) => res.tab === this.$route.query.tab
      );
      if (index >= 0) {
        this.activeTab = +index;
      }
    }
  }

  public openPermissionHelperModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: PermissionHelperModal,
      hasModalCard: true,
      props: {},
      events: {}
    });
  }

  @Watch('activeTab')
  public onTabChange(value: number): void {
    if (this.$route.query.tab === rolesAndPermissionsTabs[value].tab) {
      return;
    }

    /** set active tab for router query */
    this.$router.replace({ query: undefined });
    this.$router.push({
      query: {
        ...rolesAndPermissionsTabs[value]
      }
    });
  }
}
