




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import BasePaginatorHoc from '@/components/base/BasePaginatorHoc.vue';
import GroupListPermission from './GroupListPermission.vue';
import UserListPermission from './UserListPermission.vue';
import { Action, State } from 'vuex-class';
import { Pagination } from 'nestjs-typeorm-paginate';
import {
  FilteredGetGroup,
  FilteredGroupPayload,
  GetGroupFilterPayload,
  GetGroupsResponsePayload,
  Group
} from '@/store/modules/admin/types/admin.types';
import { RootState } from '@/store/store';
import { Debounce } from '@/jbi-shared/util/debounce.vue-decorator';
import { isDifferent, isTruthy } from '@/jbi-shared/util/watcher.vue-decorator';
import { ApiState, GeneralQueryParams } from '@/store/types/general.types';
import { isUserAllowed } from '@/utils/rbac.util';
import { PermissionsMatrixActionsEnum } from '@/store/modules/roles-and-permissions/types/roles-and-permissions.types';
import { EntityTypes } from '@/store/modules/module-tree/enums/module-tree.enums';
import { rolesAndPermissionsApi } from '@/api/roles-and-permissions.api';
import {
  MyjbiGroup,
  MyjbiGroupMember
} from '@/jbi-shared/types/myjbi-group.types';
import { adminApi } from '@/api/admin.api';
import { AxiosResponse } from 'axios';
import { filter, get } from 'lodash';

const initialState = {
  groupname: '',
  grouptype: ''
};

@Component({
  computed: {
    PermissionsMatrixActionsEnum() {
      return PermissionsMatrixActionsEnum;
    }
  },
  components: {
    BasePaginatorHoc,
    GroupListPermission,
    UserListPermission
  }
})
export default class ViewUserGroupsWithPermissionModal extends Vue {
  @Prop({ default: '' }) public firstName!: string;
  @Prop({ default: '' }) public lastName!: string;
  @Prop({ default: '' }) public email!: string;
  @Prop({ default: '' }) public id!: number;
  @Prop({ default: '' }) public groupname!: string;

  public perPage = 50;
  public page = 1;
  public sortColumn: string = 'name';
  public sortOrder: 'ASC' | 'DESC' = 'ASC';
  public filteredGroupParams: FilteredGetGroup = { ...initialState };
  public filteredUserGroups: { items: Group[] } = { items: [] };
  public expandGroupFilter = false;
  public filteredUserMembers: {
    items: MyjbiGroupMember[];
    itemCount: number;
    totalItems: number;
    pageCount: number;
  } = { items: [], itemCount: 0, totalItems: 0, pageCount: 0 };
  private groupIdsToFilter: {
    items: MyjbiGroup[];
    itemCount: number;
    totalItems: number;
    pageCount: number;
  } = { items: [], itemCount: 0, totalItems: 0, pageCount: 0 };

  @Action('admin/getGroups')
  private getGroups!: (
    options: FilteredGroupPayload
  ) => Promise<GetGroupsResponsePayload>;

  @Action('admin/getGroupTypes')
  private getGroupTypes!: () => void;

  @Action('rolesAndPermissions/getPermissionsForUsers')
  private getPermissionsForUsers!: (options: GeneralQueryParams) => void;

  @State(({ admin }: RootState) => admin.apiState.getGroups)
  private getGroupsStatus!: ApiState;

  @State(({ admin }: RootState) => admin.groups)
  private groups!: Pagination<Group[]>;

  @State(({ rbac }: RootState) => rbac.groupsUserHasAccessTo)
  private groupsUserHasAccessTo!: number[];

  @State(({ rbac }: RootState) => rbac.groupTypesUserHasAccessTo)
  private groupTypesUserHasAccessTo!: string[];

  get modalTitle(): string {
    return this.groupname
      ? (this.email = '')
      : `${this.firstName} ${this.lastName} - ${this.email}`;
  }

  @Watch('id', { immediate: true })
  public onIdChange() {
    this.fetchFilteredGroupIds();
  }

  public async fetchFilteredGroupIds() {
    if (this.id && this.email) {
      const response: AxiosResponse<
        MyjbiGroup[]
      > = await rolesAndPermissionsApi.getFilteredPermissionsGroupByUserId(
        this.id
      );
      this.groupIdsToFilter.items = response.data;
      this.groupIdsToFilter.itemCount = response.data.length;
      this.groupIdsToFilter.totalItems = response.data.length;
      this.groupIdsToFilter.pageCount = Math.ceil(
        response.data.length / this.perPage
      );
    } else if (this.id && this.email === '') {
      const params = {};
      const response = await adminApi.getMembers(this.id, params);
      this.filteredUserMembers = {
        items: response.data.items,
        itemCount: response.data.itemCount,
        totalItems: response.data.totalItems,
        pageCount: response.data.pageCount
      };
    }
  }

  get filteredGroups() {
    if (!this.groups || !this.groups.items) {
      return {
        items: [],
        itemCount: 0,
        totalItems: 0,
        pageCount: 0
      };
    }

    const parentGroups = this.groupIdsToFilter?.items.filter((item) => item.id);

    const subGroups = this.groupIdsToFilter?.items.filter((item) =>
      parentGroups.some(
        (parent) => item.path.startsWith(parent.path) && item.id !== parent.id
      )
    );

    const filteredItems = [...parentGroups, ...subGroups];
    return {
      items: filteredItems,
      itemCount: filteredItems.length,
      totalItems: filteredItems.length,
      pageCount: Math.ceil(filteredItems.length / this.perPage)
    };
  }

  get computedItems() {
    return this.email === ''
      ? get(this.filteredUserMembers, 'items', 0)
      : get(this.filteredGroups, 'items', 0);
  }

  get computedTotalCount() {
    const total =
      this.email === ''
        ? get(this.filteredUserMembers, 'totalItems', 0)
        : this.filteredGroups.totalItems;

    const overflow = total - this.perPage;
    if (overflow > 0 && overflow <= 2) {
      return this.perPage;
    }
    return total;
  }

  public fetchPermissionsForUsers(): void {
    const params: GeneralQueryParams = {
      limit: 10,
      page: 1,
      searchQuery: this.firstName,
      sortOrder: 'ASC'
    };
    this.getPermissionsForUsers(params);
  }

  public isUserAllowed(
    action: PermissionsMatrixActionsEnum,
    module: string
  ): boolean {
    return isUserAllowed(
      action,
      module,
      EntityTypes.GROUP,
      this.groupTypesUserHasAccessTo
        ? this.groupTypesUserHasAccessTo[0]
        : undefined,
      this.groupsUserHasAccessTo ? this.groupsUserHasAccessTo[0] : undefined
    );
  }

  public handleResetFilter() {
    this.filteredGroupParams = { ...initialState };
  }

  public async getGroupList(params: FilteredGroupPayload) {
    await this.getGroups(params);

    if (this.groupIdsToFilter && this.groupIdsToFilter.items.length > 0) {
      return this.filteredGroups;
    }
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    if (this.$route.query.tab !== 'Permissions') {
      return;
    }

    this.perPage = perPage || +this.$route.query.limit;
    this.page = page || +this.$route.query.page;

    if (this.id && this.email === '') {
      const params = {
        page: this.page,
        limit: this.perPage
      };
      const response = await adminApi.getMembers(this.id, params);
      this.filteredUserMembers = {
        items: response.data.items,
        itemCount: response.data.itemCount,
        totalItems: response.data.totalItems,
        pageCount: Math.ceil(response.data.totalItems / this.perPage)
      };
    } else {
      if (
        this.filteredGroupParams.groupname?.length ||
        this.filteredGroupParams.grouptype?.length
      ) {
        this.expandGroupFilter = true;
      }
      await this.getGroupList({
        groupname:
          (this.$route.query.groupname as string) ||
          this.filteredGroupParams.groupname,
        grouptype:
          (this.$route.query.grouptype as string) ||
          this.filteredGroupParams.grouptype,
        limit: this.perPage,
        page: this.page,
        sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
        sortOrder:
          (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
      });
      this.expandGroupFilter = false;
      if (
        this.filteredGroupParams.groupname?.length ||
        this.filteredGroupParams.grouptype?.length
      ) {
        this.expandGroupFilter = true;
      }
    }
  }

  public async handleSort(data: any) {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const params = {
      groupname:
        (this.$route.query.groupname as string) ||
        this.filteredGroupParams.groupname,
      grouptype:
        (this.$route.query.grouptype as string) ||
        this.filteredGroupParams.grouptype,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    };

    await this.getGroupList(params);
  }

  public async handleFilter() {
    this.perPage = +this.$route.query.limit;
    const queryFilter: GetGroupFilterPayload = {
      groupname: (this.$route.query.groupname as string) ?? '',
      grouptype: (this.$route.query.grouptype as string) ?? ''
    };

    if (
      JSON.stringify(queryFilter) !== JSON.stringify(this.filteredGroupParams)
    ) {
      this.page = 1;
      this.expandGroupFilter = false;

      setTimeout(async () => {
        await this.getGroupList({
          groupname: this.filteredGroupParams.groupname,
          grouptype: this.filteredGroupParams.grouptype,
          limit: +this.$route.query.limit || this.perPage,
          page: 1,
          sortColumn:
            (this.$route.query.sortColumn as string) || this.sortColumn,
          sortOrder:
            (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
        });
        this.$router.push({
          path: this.$route.path,
          query: {
            tab: 'Permissions',
            groupname: this.filteredGroupParams.groupname!,
            grouptype: this.filteredGroupParams.grouptype!,
            limit:
              (this.$route.query.limit as string) || this.perPage.toString(),
            page: (this.$route.query.page as string) || this.page.toString(),
            sortColumn:
              (this.$route.query.sortColumn as string) || this.sortColumn,
            sortOrder:
              (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
          }
        });

        this.expandGroupFilter = true;
        if (
          this.filteredGroupParams.groupname?.length === 0 &&
          this.filteredGroupParams.grouptype?.length === 0
        ) {
          this.expandGroupFilter = false;
        }
      }, 800);
    }
  }

  get GroupListPermission() {
    return GroupListPermission;
  }

  get UserListPermission() {
    return UserListPermission;
  }

  public async handleResetQuery() {
    this.getGroupList({
      groupname:
        (this.$route.query.groupname as string) ||
        this.filteredGroupParams.groupname,
      grouptype:
        (this.$route.query.grouptype as string) ||
        this.filteredGroupParams.grouptype,
      limit: +this.$route.query.limit || this.perPage,
      page: +this.$route.query.page || this.page,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    });
  }

  public async handleTabResetQuery() {
    if (
      isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-group_types-read_group_types'
      )
    ) {
      this.getGroupTypes();
    }
    this.filteredGroupParams = { ...initialState };

    await this.getGroupList({
      page: this.page,
      limit: this.perPage,
      sortColumn: (this.$route.query.sortColumn as string) || this.sortColumn,
      sortOrder:
        (this.$route.query.sortOrder as 'ASC' | 'DESC') || this.sortOrder
    });
  }
  public mounted() {
    if (this.$route.params.context) {
      this.$router.replace({ name: this.$route.name, params: {}, query: {} });
    }
    if (
      isUserAllowed(
        PermissionsMatrixActionsEnum.READ,
        'group_administration-group_types-read_group_types'
      )
    ) {
      this.getGroupTypes();
    }

    // Set up the initial filtered parameters for groupname and grouptype
    this.filteredGroupParams = {
      groupname: (this.$route.query.groupname as string) || '',
      grouptype: (this.$route.query.grouptype as string) || ''
    };

    this.perPage = +this.$route.query.limit || this.perPage;
    this.page = +this.$route.query.page || this.page;

    this.expandGroupFilter =
      Boolean(this.filteredGroupParams.groupname) ||
      Boolean(this.filteredGroupParams.grouptype);

    this.getGroupList({
      groupname: this.filteredGroupParams.groupname,
      grouptype: this.filteredGroupParams.grouptype,
      limit: this.perPage,
      page: this.page,
      sortColumn: this.sortColumn,
      sortOrder: this.sortOrder
    });
  }

  @Watch('$route.query.tab')
  public routeParam() {
    if (this.$route.query.tab === 'Permissions') {
      this.expandGroupFilter = false;
      this.handleTabResetQuery();
    }
  }

  @Watch('getGroupsStatus.success')
  @Debounce(400)
  @isDifferent
  @isTruthy
  public getGroupsStatusSuccess() {
    this.$emit('activateTab');
  }

  @Watch('getGroupsStatus.error')
  @isDifferent
  @isTruthy
  public getGroupsStatusError() {
    this.$emit('activateTab');
  }

  public closeModal(): void {
    this.$emit('close');
  }

  @Watch('$route')
  onRouteChange() {
    this.closeModal();
  }
}
